@import "src/assets/scss/variables";
.chat-search-component{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color:#FFFFFF;
  border:1px solid #F0F0F0;
  gap: 20px;
  padding: 0 12px;

  .chat-input-wrapper{
    flex: 1;
  }

  .chat-send-message-btn{
    color: #FFFFFF !important;
    border:none !important;
    box-shadow: none !important;
    background: $primary-color !important;
    &:hover{
      color: #FFFFFF !important;
    }
  }

  .ant-input{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-form-item{
    margin-bottom: 0 !important;
    border: none !important;
  }
}