@import "mixins";

$font-family: 'Inter';
$primary-color: #10c4d3;
$text-on-primary-color: #FFF;
$accent-color: #0c80e3;
$text-on-accent-color: #FFF;
$background-color: #ffffff;
$blue-color: #3ca7f4;
$text-light:#808080;
$backdrop-color: #41D6C3;
$light-black:#1e1e1e;
$light-gray:#D3D3D3;
$vitawerks-font-color:#121F3E;
$black:#000000E0;
$light-gray-second:#52525B;
$chip-color:#C8FCFE;
$active-table-row:#F2FEFF;
$border-color:#D2D2D2;
$chat-active-card-color:#EFF2F5;
$white:#FFFFFF;
$box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$disabled-text-color: #27272A;
$disabled-bg-color: #f5f5f5;
$disabled-border-color: #d9d9d9;
$disabled-placeholder-color: #A1A1AA;