@import "src/assets/scss/variables";
.chat-user-list{
  height: 500px !important;
  .chat-user-list-wrapper{
    height: 380px;
    overflow-y: auto;
    margin-bottom: 20px;
    .radio-wrapper{
      width: 100% !important;
    }
    .chat-user{
      padding: 10px;
      font-size: 14px;
      font-weight: 600;
      transition: background-color 0.3s linear;

      &:hover{
        background-color: #D2D2D2;
        cursor: pointer;
      }
    }
    .chat-loading-container{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  .chat-button{
    width: 100%;
    &:hover{
      color:black !important
    }
  }

  // Custom Radio Button Styling
  .ant-radio-wrapper {
    color: $primary-color; // Default text color
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: $primary-color;
    background-color: $primary-color;
  }

  .ant-radio-inner {
    border-color: $primary-color;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: $primary-color;
  }

  .ant-radio-checked::after {
    border-color: $primary-color;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 4px rgba(16, 196, 211, 0.6);
  }

}
