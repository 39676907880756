.in-app-notification-component{
  width: 450px;
  height: 450px;
  overflow-y: auto;
  border-top: 1px solid #D2D2D2;
  padding-top: 10px;
  .in-app-loading{
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification-card{
    display: flex;
    gap: 20px;
    margin-bottom: 12px;
    padding: 12px;
    border-bottom: 1px solid #D2D2D2;
    .notification-message{
      font-weight: 500;
      transition: all 0.4ms linear;
      &:hover{
        text-decoration: underline;
        cursor: pointer;
        color: #0c8c9a;
      }
    }
    .notification-timestamp{
      font-size: 12px;
      color: #8c8c8c;
      margin-top: 10px;
    }
    &:hover{
      background: #F8F8F8;
      cursor: pointer;
    }
  }
  .notification-card-loading{
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 12px;
    border-bottom: 1px solid #D2D2D2;
    .ant-skeleton-paragraph{
      margin: 0 0 10px 0 !important;
    }
  }
}