.save-template-component{
  margin-top: 10px;
  height: 100%;
  .template-text-editor{
    border: 1px solid #D2D2D2;
    .jodit-container {
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      height: 300px !important;
    }

    .jodit-toolbar {
      border-bottom: 1px solid #d9d9d9;
    }

    .jodit-workplace {
      height: 235px !important;
      border-radius: 10px;
    }
  }
}