.bill-rate-component{
  width: 100% !important;
  height:calc(100vh - 320px) !important;
  overflow-y:scroll;
  scroll-behavior: smooth;
  display: flex;
  gap: 20px;
  .ant-form-item{
    margin-bottom: 0 !important;
  }
  .bill-rate-table-wrapper{
    flex: 2;
  }
  .contract-right-rate-card{
    flex: 1;
  }
}