@import "src/assets/scss/common";

.hcp_details {
	width: calc(100vw - 320px) !important;
	.dnr-table-wrapper{
		height: fit-content !important;
	}
	.title-rating-container{
		display: flex;
		justify-content: space-between;
	}

	.rating-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.rating-title {
		color: $text-light;
        font-size: 18px;
		}

		.rating-content {
        color: $primary-color;
        font-size: 28px;
		}
	}
	.d-flex {
		display: flex;
		gap: 15px;
	}
	.flex-1 {
		flex: 1px !important;
		max-width: 32% !important;
	}
	.MuiPaper-elevation1{
		box-shadow: none !important;
	}
	.MuiAccordionSummary-root{
		padding: 0px !important;
	}
	.MuiAccordionSummary-content{
		p{
			font-size: large;
			font-weight: 600;
		}
	}
	.summary{
		overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        text-align: left;
		max-width:calc(80vw - 100px);
	}
	.attachments_wrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		.attachments {
			flex: 50%;
			margin-top: 20px;
		}
	}
	h4{
		color:$text-light;
		font-weight: 400;
	}
	h3{
		color:$light-black;
	}
	p{
		color:$light-black;
		margin-top: -10px;
		overflow-wrap: break-word;
		word-wrap: break-word;
		hyphens: auto;
		text-align: left;
	}
	.title-count{
		color:$light-black;
		margin-bottom: 0px;
		margin-top: 10px;
	}
	.MuiAccordionDetails-root{
		padding: 0px !important;
	}

}
.hcp-name-type {
	margin-left: 20px;
	justify-content: center;
	h2 {
		margin-bottom: 0px;
	}
	p {
		margin-top: 0px;
	}
}
.hcp-photo-details-wrapper {
	margin: auto;
	justify-content: space-between;
	padding-bottom: 15px !important;
	.hcp-status{
		color: $primary-color;
		font-weight: 400;
	}
	.ratings {
		text-align: center;
		h4 {
			color: $text-light;
			margin-bottom: 0px;
		}
		p {
			color: $primary-color;
			font-size: 20px;
			margin-top: 0px;
		}
	}
}