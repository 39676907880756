@import "src/assets/scss/variables";
.file-drop-zone-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.ant-upload-drag{
  &:hover{
    border-color:  $backdrop-color !important;
  }
}
.resume-file-holder-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px;
  border-radius: 8px;
  border:1px solid  #41D6C3;
  background:  #F2FEFF;
  width: 100%;
}

.file-clear-btn{
  color: $backdrop-color;
  border: 1px solid $backdrop-color !important;
  width: 100%;
  flex: 1;
  margin-right: 15px;
  &:hover{
    color: $backdrop-color !important;
  }
}
.file-save-btn{
  background: $backdrop-color;
  color: #FFFFFF;
  border: none !important;
  width: 100%;
  flex: 1;

  &:hover{
    background: $backdrop-color !important;
    color: #FFFFFF !important;
  }
}
.resume-parser-loading{
  color: var(--Components-Modal-Global-colorText, rgba(0, 0, 0, 0.88));
  text-align: center;
  font-size: var(--Components-Modal-Component-titleFontSize, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Typography-Line-Height-lineHeightLG, 24px); /* 150% */
}