.document-card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .document-attachment-type{
    width: 50%;
    text-wrap: wrap;
    word-break: break-all;
    color: #0c0b0b;
  }
  .document-attachment-btn{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.heading{
  font-weight: 600;
  margin-bottom: 22px;
  color: #10c4d3;
}
.submission-title{
  font-weight: 500;
  margin-top: 20px;
  color: #0c0b0b;
}
.ant-picker-dropdown {
  z-index: 40000 !important;  // Ensure it's above the Drawer
}