@import "src/assets/scss/variables";

.chat-card{
  border-bottom: 1px solid $border-color;
  display: flex;
  gap: 10px;
  padding: 12px;
  width: 100%;


  &:hover{
    background: $chat-active-card-color;
    cursor: pointer;
  }

  .chat-left{
  }

  .chat-middle{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    overflow: hidden;

    .chat-user-name{
      font-weight: 500;
      font-size: 16px;
    }

    .chat-last-message{
      font-size: 12px;
      color: #667681;
      .active{
        color: black;
        font-weight: 500;
      }
    }

    .truncate-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .chat-right{
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .chat-timestamp{
      //color: #919EAB;
      color: $primary-color;
      text-align: right;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
    .chat-badge{
      width: 12px;
      height: 12px;
      background: $primary-color;
      border-radius: 50%;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.active{
  background: $chat-active-card-color;
}