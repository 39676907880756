@import "src/assets/scss/variables";

html,body{
  font-size: 14px;
  font-family: $font-family, sans-serif;
  box-sizing: border-box;
}

body {
  //text-transform: capitalize;
  margin: 0;
  font-family: $font-family, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF !important;
}

.ant-typography,
.ant-btn,
.ant-input,
.ant-select,
.ant-menu,
.ant-table {
  font-family: $font-family !important;
}

// Override Input, Select, DatePicker, and other components
.ant-input,
.ant-select-selector,
.ant-input-affix-wrapper,
.ant-picker{
  &:hover,
  &:focus,
  &:active,
  &.ant-input-focused {
    border-color: $backdrop-color !important;
    box-shadow: 0 0 5px rgba(65, 214, 195, 0.5) !important;
  }
}

// Override focused states to remove blue outline
.ant-input:focus,
.ant-select-focused .ant-select-selector,
.ant-picker-focused {
  border-color: $backdrop-color !important;
  box-shadow: 0 0 5px rgba(65, 214, 195, 0.5) !important;
}

// Remove default Ant Design blue color in focus and active states
.ant-input:focus,
.ant-select-selector:focus,
.ant-input-affix-wrapper:focus,
.ant-picker:focus {
  outline: none !important;
}

// Override active and hover states for all elements
.ant-input:hover,
.ant-select:hover .ant-select-selector,
.ant-input-affix-wrapper:hover,
.ant-picker:hover {
  border-color: $backdrop-color !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:active{
  border-color: $backdrop-color !important;
  box-shadow: none !important;
}

.ant-input-outlined:focus-within{
  border-color: $backdrop-color !important;
  box-shadow: none !important;
}

// Remove default blue border from success, warning, and error states
.ant-input-status-success:not(.ant-input-disabled),
.ant-input-status-warning:not(.ant-input-disabled),
.ant-input-status-error:not(.ant-input-disabled) {
  border-color: $backdrop-color !important;
}

.ant-tabs-tab{
  &:hover{
    color:$backdrop-color !important;
  }
}
.ant-tabs-tab-active{
  .ant-tabs-tab-btn{
    color:$backdrop-color !important;
  }
}

.ant-menu-vertical{
  border-inline-end:none !important;
}


.ant-tabs-ink-bar {
  background-color: $backdrop-color !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: $backdrop-color !important;
}

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $backdrop-color !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: $backdrop-color !important;
  background-color: $backdrop-color !important;
}
.ant-checkbox-inner:after{
  background-color: $backdrop-color !important;
}
/* Base disabled styles */
[class*='-disabled'] {
  /* Text and background */
  &,
  .ant-select-selection-item,
  .ant-select-arrow,
  .ant-input-prefix,
  .ant-input-suffix,
  &.ant-select-item-option {
    color: $disabled-text-color !important;
    opacity: 1 !important;
  }

  /* Borders */
  &,
  .ant-select-selector {
    border-color: $disabled-border-color !important;
  }

  /* Placeholders */
  &::placeholder,
  .ant-input::placeholder,
  .ant-select-selection-placeholder {
    color: $disabled-placeholder-color !important;
  }
}
