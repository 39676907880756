@import "src/assets/scss/common";

.facility-add-component{
  position: relative;
.facility-main {
  //padding: 0.5rem 3rem 3rem 3rem;
  padding-top: 50px;
  max-width: 1150px;
  margin: 0 auto;

  .delete-image {
    margin-left: 10px;
    color: $light-black;
    text-decoration: underline;
    margin-top: 0px;
  }

  .MuiTypography-body1 {
    color: $text-light !important;
  }

  .form-container {
    position: relative;
  }

  .flex-1 {
    flex: 1px;
  }

  .phone-number {
    width: 75%;
  }

  .extension-number {
    width: 25%;
    margin-left: 0.5rem;

    //.MuiOutlinedInput-input {
    //  padding: 13px !important;
    //}
  }

  .card-header {
    color: #000;
    font-size: 18px;
    font-weight: 500;
  }

  .header {
    color: $primary-color;
    font-size: 22px;
  }

  .close-icon {
    fill: red;
  }

  .add-icon {
    fill: green;
  }

  .edit-icon {
    fill: orange;
  }

  .delete-icon {
    fill: red;
  }

  .custom-border {
    padding: 0.8rem 3rem 2rem 2rem !important;
    border-radius: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .generic-add-multiple {
    color: $primary-color;
    text-decoration: underline;
    margin: auto;
    font-weight: 500;
    cursor: pointer;
    font-size: 18px;
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 1.5rem;
    margin: 2rem 0;
  }

  .MuiTypography-root .MuiTypography-h5 {
    color: $light-black;
  }

  .attachments_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .attachments {
      flex: 50%;
      margin-top: 20px;
    }
  }

  .facility-about {
    margin: 2rem 0;

    .MuiOutlinedInput-multiline {
      padding: 0 !important;
    }
  }

  .facility-documents {
    margin: 2rem 0;
  }

  .facility-special-consideration {
    margin: 2rem 0;
  }

  .facility-actions {
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }


  label[data-shrink='false'] {
    color: gray !important;
  }

  .image_actions {
    p {
      color: $primary-color;
      margin-left: 0px;
    }
  }

  .MuiInputBase-inputMultiline {
    border: none !important;
  }

  .facility-image-header {
    color: #41d6c3 !important;
    font-weight: 420 !important;
    font-size: medium !important;
  }
}

.facility-add-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;

  .number-container {
    display: flex;
    width: 100%;
    gap: 10px;

    .phone-number {
      width: 75%;
    }

    .extension-number {
      width: 25%;
    }
  }

  .add-member-icon {
    cursor: pointer;
  }
}
  .ant-form-item{
    margin-bottom: 0 !important;
  }
}
