:root {
  --jd-color-background-default: #ffffff;
  --jd-color-border: #d3d3d3;
  --jd-color-panel: #ffffff;
  --jd-color-icon: #1e1e1e;
}

.jodit-editor-wrapper{
  color: #0c0b0b !important;
}

.jodit-container,
.jodit-wysiwyg,
.jodit-workplace {
  border: none !important;
  height: 350px !important;
}

.jodit-toolbar {
  border: none !important;
}

.jodit-toolbar__box {
  background: #FFFFFF !important;
  border-bottom: 1px solid #ccc !important;
}

.jodit-wysiwyg iframe,
.jodit-wysiwyg {
  border: none !important;
}



.jodit-toolbar__box {
  white-space: nowrap !important; /* Prevent wrapping */
  overflow-x: auto !important; /* Add horizontal scroll if needed */
  flex-wrap: nowrap !important; /* Prevent flex wrapping */
}

.jodit-toolbar {
  width: 100% !important; /* Ensure the toolbar takes full width */
  overflow: hidden !important; /* Hide overflow */
}

/* Optional: Reduce padding and margins to fit more buttons */
.jodit-toolbar-button {
  margin: 2px !important; /* Reduce margin between buttons */
  padding: 4px 6px !important; /* Reduce padding inside buttons */
}

.jodit-status-bar__item-right {
  display: none !important;
}

.jodit-status-bar {
  display: none !important;
}
