@import "src/assets/scss/variables";
.log-item {
  margin-bottom: 16px;
}

.log-accordion{
  background: #FFFFFF !important;
  border: 1px solid $light-gray !important;
}

.activity-header-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .log-strings-wrapper{
    font-weight: 500;
    font-size: 16px;
  }
  .log-updated-by{
    color: $light-black;
    margin-left: 10px;
  }
  .log-updated-name{
    font-weight: 600;
    margin-left: 10px;
    color: $light-gray-second;
  }
}

.log-item-header {
  font-weight: bold;
  margin-bottom: 8px;
}

.log-item-body {
  margin-left: 16px;
}

.log-status-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.log-item-action {
  margin-right: 8px;
}

.updated-date {
  color: rgba(0, 0, 0, 0.45);
}

.activity-log-object-wrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.activity-log-array-wrapper{
  margin: 10px 0;
}