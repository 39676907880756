.add-job-form-container-whole {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .form-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 9px 24px;

    .header-title{
      margin: auto;
      text-align: center;
    }

    .next-button {
      background-color: #3ad4c5;
      border: none;
      color: #ffffff;
      font-size: 1rem;
      padding: 10px 20px;

      &:hover {
        background-color: #32b9ab !important;
        color: #FFFFFF !important;
      }
    }
  }

  .form-body {
    flex: 1;
    overflow: auto;
    border-radius: 12px;
    border: 1px solid var(--gray-300, #D4D4D8);
    background: var(--white, #FFF);
    margin: 30px 144px 20px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }

  .form-footer {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #ffffff; // Optional, for contrast with content
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow for separation

    .exit-button {
      border: 1px solid #d9d9d9;
      background-color: #ffffff;
      color: #595959;
      margin-right: 1rem;
      font-size: 1rem;
      padding: 10px 20px;

      &:hover {
        background-color: #f5f5f5;
        color: #262626;
      }
    }

    .next-button {
      background-color: #3ad4c5;
      border: none;
      color: #ffffff;
      font-size: 1rem;
      margin-right: 70px;
      padding: 10px 20px;
      &:hover {
        background-color: #32b9ab !important;
      }
    }
  }

}


/* Custom styles for the Ant Design Steps component */

/* Red background and black tick mark for completed steps */
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #41D6C3 !important; /* Red background for completed steps */
  border-color: #41D6C3 !important; /* Red border color for completed steps */
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: #fff !important; /* Set the tick mark (icon) color to black */
}

/* Red background for the current step */
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #41D6C3 !important; /* Red color for the current step */
  border-color: #41D6C3 !important; /* Red border color for the current step */
}

/* Gray color for uncompleted steps */
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff !important; /* White color for uncompleted steps */
  border-color: #d9d9d9 !important; /* Gray border color for uncompleted steps */
}

/* Change the lines between the completed steps to red */
.ant-steps-item-finish .ant-steps-item-tail {
  border-color: #41D6C3 !important; /* Red color for the lines between completed steps */
}

/* Keep lines of uncompleted steps in their default color */
.ant-steps-item-wait .ant-steps-item-tail {
  border-color: #d9d9d9 !important; /* Default gray color for the lines of uncompleted steps */
}

.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #41D6C3 !important;
}