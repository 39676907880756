.change-status-component{
  height: 470px;
  .change-status-text-editor-wrapper{
    margin-top:10px;
    margin-bottom: 20px;
    height: 280px;
    border: 1px solid #D2D2D2;
    border-radius: 5px;

    .ql-container {
      border: none !important;
      height: 100%;
      overflow-y: scroll;
      color: #0c0b0b;
    }
  }
}