.chat-header-component{
  display: flex;
  gap: 20px;
  .chat-user-title{
    font-size: 16px;
    font-weight: 700;
  }
  .chat-last-seen{
    font-size: 12px;
    font-weight: 500;
  }
}