.contract-rate-card-component{
  display: flex;
  gap: 20px;
  .contract-left-rate-card{
    flex:2;
    height: calc(100vh - 35vh) !important;
    overflow-y:scroll;
    width: 100%;
  }
  .contract-right-rate-card{
    flex:1;
    display: flex;
    flex-direction:column;
    gap:12px;
    .gross-rate-card{
      display: flex;
      flex-direction: column;
      padding: 8px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(--blue-500, #3B82F6);
      background: var(--blue-50, #EFF6FF);
    }
    .guaranteed-week-card{
      display: flex;
      padding: 8px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(--Vitawerks-Main, #41D6C3);
      background: var(--Main-100, #F2FEFF);
    }

    .gross-total-card{
      display: flex;
      padding: 8px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #9254DE;
      background:#F9F0FF;
    }
    .company-gross-card{
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(--orange-300, #FDBA74);
      background: var(--orange-50, #FFF7ED);
    }
    .candidate-gross-card{
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(--yellow-300, #FDE047);
      background: var(--yellow-50, #FEFCE8);
    }
    .tax-amount-card{
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
    }
  }
  .title{
    color: var(--gray-800, #27272A);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .sub{
    display: flex;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }
  .sub-title{
    color: var(--gray-600, #52525B);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .border{
    height: 1px;
    border-bottom: 1px solid #E4E4E7;
  }
}