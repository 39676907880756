@import "src/assets/scss/variables";

.chat-component{
  color: #0c0b0b;
  width: calc(100vw - 265px);
  height: calc(100vh - 80px);
  padding: 20px;
  display: flex;
  gap: 20px;

  .chat-list-wrapper{
    background: $white;
    box-shadow: $box-shadow;
    height: 100%;
    width: 35%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .chat-user-search-wrapper{
      display: flex;
      align-items: center;
      gap: 20px;
      .chat-user-search-input-wrapper{
        flex: 1;
        .ant-form-item{
          margin-bottom: 0 !important;
        }
      }
      .chat-new-user{
        border: 1px solid $primary-color;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color:$primary-color;
        cursor: pointer;
        border-radius: 5px;
      }
    }

    .chat-card-wrapper{
      margin-top: 20px;
      flex:1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: auto;
    }
  }
  .chat-main-wrapper{
    background: $white;
    box-shadow: $box-shadow;
    height: 100%;
    width: 65%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .message-container {
      display: flex;
      margin-bottom: 10px;
      max-width: 70%;
    }

    .sent-message {
      align-self: flex-end;
      background: $primary-color;
      color: white;
      padding: 10px 15px;
      //border-radius: 15px 15px 0px 15px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 15px;
      max-width: 60%;
    }

    .received-message {
      align-self: flex-start;
      background: #f1f1f1;
      padding: 10px 15px;
      //border-radius: 15px 15px 15px 0px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 15px;
      max-width: 60%;
    }
    .message-text{
      margin-bottom: 10px;
    }
    .message-timestamp{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      font-size: 10px;
    }
    .chat-main-area-wrapper{
      background-color: $active-table-row;
      flex: 1;
      height: 100%;
      padding: 10px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      overflow: hidden;

      .chat-message-area{
        padding: 12px;
        display: flex;
        flex: 1;
        overflow-y: auto;
        flex-direction: column-reverse;
        border-top-left-radius: 10px ;
        border-top-right-radius: 10px ;
      }
      .chat-message-search-wrapper{
        background-color: $white;
        height: 50px;
        border-radius: 10px ;
      }
    }
    .no-chat-selected-wrapper{
      background-color: $chip-color;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      .no-chat-selected-title{
        font-size: 20px;
        font-weight: 600;
      }
      .no-chat-selected-button{
        font-weight: 500;
        background-color: $primary-color;
        padding: 10px;
        color: #F0F0F0;
        cursor: pointer;
        border-radius: 10px;
      }
    }
  }
}