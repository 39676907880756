@import "src/assets/scss/variables";

.email-editor-component{
  display: flex;
  gap: 15px;
  height: 100%;


  .email-editor-wrapper{
    width: 100%;
    flex: 3;
    border: 1px solid var(--gray-300, #D4D4D8);
    border-radius: 12px;
    background-color: #FFFFFF;
    height: calc(100vh - 200px);
    overflow-y: scroll;

    .email-editor-dropdown-wrapper{
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 15px;
      border-bottom: 1px solid var(--gray-300, #D4D4D8);
      .email-editor-field{
        width: 33.3%;
      }
      .ant-form-item{
        margin-bottom: 0px !important;
      }
    }

    .email-editor{
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      height: 100%;



      .email-subject-input-wrapper{
        position: relative;

        .email-subject-input{
          width: 100%;
          padding: 6px;
          font-family: inherit;
          border-bottom: 1px solid var(--gray-300, #D4D4D8);
          .ant-input{
            border: none;
            outline: none;
            box-shadow: none !important;
          }
          .ant-input:focus {
            outline: none;
            box-shadow: none !important;
          }
        }

        .email-subject-tag-wrapper{
          position: absolute;
          top: 50%;
          transform: translate(-50%,-50%);
          right: -22px;
          cursor: pointer;
          color: #0c0b0b;
          background: #FFFFFF;
          padding: 5px;

          &:hover{
            color:$primary-color
          }
      }
      }

      .ant-form-item{
        margin-bottom: 0px !important;
      }

      .email-quill-wrapper{
        flex: 1;
        height: 100%;
        overflow: hidden;

        .quill .ql-container {
          height: 100% !important;
          overflow: hidden !important;
        }
      }

      .email-attachment-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;


        .attachment-upload{
          cursor: pointer;
          padding: 10px;
        }

        .file-preview-wrapper{
          display: flex;
          align-items: center;
          gap:10px;
          border: 1px solid var(--gray-300, #D4D4D8);
          padding: 8px;
          border-radius: 5px;

          .file-preview-close-icon{
            cursor: pointer;
          }
        }
      }
      .delete-btn{
        &:hover {
          background-color:$background-color;
        }
      }
    }
  }
  .email-customize-body-wrapper{
    flex: 1;
    border: 1px solid var(--gray-300, #D4D4D8);
    border-radius: 12px;
    padding: 15px;
    background-color: #FFFFFF;


    .email-option-label{
      font-size: 14px;
      font-weight: 500;
      color: $light-gray-second;
    }
  }
}