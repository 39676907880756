.matching-applicant-component{
  border-radius: 12px;
  border: 1px solid var(--gray-300, #D4D4D8);
  background: var(--white, #FFF);
  padding: 24px;
  overflow: auto;
  max-height: 80vh;
  height: calc(100vh -  180px);
  width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 24px;

  .matching-applicant-field-wrapper{
    width: 300px !important;
    border-radius: 8px;
    border: 1px solid  #E4E4E7;
    background: #FAFAFA;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .ant-form-item{
      margin-bottom: 0 !important;
    }

    .matching-applicant-field-title{
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%
    }
  }
  .matching-applicant-table-wrapper{
    flex: 1;
    width: 700px !important;
  }
}