.posting-config-component{

  .posting-config-content-wrapper{
    margin: 20px 0;
    display: flex;
    flex-direction: column;
  }
  .ant-form-item .ant-form-item-control-input{
    min-height: 0 !important;
  }
}