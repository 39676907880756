.pay-rate-component{
  width: 100% !important;
  height:calc(100vh - 320px) !important;
  overflow-y:scroll;
  scroll-behavior: smooth;
  .pay-rate-card{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    .shift-row{
      display: flex;
      gap: 20px;
      flex-grow: 1;
      width: 100%;

      .time-wrapper{
       width: 100% !important;
      }
    }
  }

  .ant-form-item{
    margin-bottom: 0 !important;
  }
}
