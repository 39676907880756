@import "src/assets/scss/variables";

.responsive-table-container {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  border-radius: 0.7rem;

  .unread-row {
    background: $active-table-row !important;
  }

  .unread-row .ant-table-cell-fix-left,
  .unread-row .ant-table-cell-fix-right {
    background-color: $active-table-row !important; /* Inherit the row color */
  }

  .read-row {
    background: #FFFFFF !important;
  }

  .read-row .ant-table-cell-fix-left,
  .read-row .ant-table-cell-fix-right {
    background-color: white !important; /* Inherit the row color */
  }

  /* Apply hover effect only to non-fixed columns */
  .ant-table-tbody > tr:hover > td:not(.ant-table-cell-fix-left):not(.ant-table-cell-fix-right) {
    background-color: inherit !important;
  }

  .ant-table-wrapper {
    border-radius: 10px;
    box-shadow: none;
    border-bottom: 1px solid #F0F0F0;
    border-left: 0.7px solid #F0F0F0;
    border-right: 0.7px solid #F0F0F0;
  }

  .ant-table-header {
    font-family: $font-family;
  }


  .ant-table-body {
    min-height: 49vh !important;
    max-height: 65vh !important;
    border-bottom: 0.7px solid #DFDFDF;
    font-family: $font-family;
  }

  .ant-table-expanded-row-fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh !important;
    max-height: 60vh !important;
  }

  .ant-pagination {
    //margin: 1rem 1rem !important;
    padding: .5rem 1rem !important;
    display: flex;

    .ant-pagination-item {
      padding: 8px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      //font-family: $font-family;
    }

    .ant-pagination-total-text {
      flex: 1;
      text-align: left;
    }

    .ant-pagination-item-active {
      background-color: #F1FDFF;
      border-color: #10c4d3;
      //border:1px solid $side-menu-active-link-bg-color;
      a {
        color: #000000;
      }
    }

    .ant-pagination-prev, .ant-pagination-next {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-pagination-options {
      padding-left: 2rem;
    }

    .ant-pagination-options .ant-select-selector {
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -5px;
    }

    .ant-select-selector .ant-select-selection-item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-family;
    }
  }
  .ant-table-row-expand-icon{
    border-color:$primary-color !important;
    color: $primary-color !important;
  }
}

//.ant-table-thead .ant-table-cell{
//  //padding-left: 20px !important;
//  padding-right: 20px !important;
//}
//
//.ant-table-row .ant-table-cell {
//