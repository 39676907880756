@import "src/assets/scss/common";

.add-hcp-component{
  position: relative;
  .add-hcp {
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding-top: 70px;

    .custom-border {
      margin: 1rem;
      padding: 1rem 3rem 2rem 2rem !important;
      border-radius: 0.5rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .gender {
      color: $text-light;
      font-weight: 450;
    }
    .gender-wrapper {
      flex-wrap: wrap;
    }
    .MuiTypography-body1 {
      color: $text-light;
    }
    .MuiIconButton-label {
      color: $text-light;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .generic-add-multiple {
      color: $primary-color;
      text-decoration: underline;
      margin: auto;
      font-weight: 500;
      cursor: pointer;
      font-size: 18px;
    }

    .contract_actions {
      margin-top: -15px;
      margin-left: 15px;
      color: $primary-color;
      text-decoration: underline;
    }
    .card-header {
      color: #000;
      font-size: 18px;
      font-weight: 500 !important;
    }
    .header {
      color: $primary-color;
      font-size: 22px;
      margin-left: 1rem;
    }
    .close-icon {
      fill: red;
    }
    .add-icon {
      fill: green;
    }
    .edit-icon {
      fill: green;
    }
    .delete-icon {
      fill: red;
    }
    h3 {
      color: #000;
    }
    .hcp-basic-details {
      .hcp-contact {
        width: 75%;
      }
      h3 {
        color: #000;
      }
      .hcp-basic-details {
        .hcp-contact {
          width: 75%;
        }

        .professional-details {
          width: 50%;
        }
      }
      .hcp-documents {
        margin: 2rem 0;
      }
    }
    .add-hcp-actions {
      padding-bottom: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .input-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 1.5rem;
      margin: 2rem 0;

      .flex-1 {
        flex: 1px;
      }
      .input-field {
        margin-top: 10px;
        flex: 0.7;
        display: flex;
        gap: 0.5rem;
      }
    }

    .attachments_wrapper {
      display: grid;
      grid-template-columns: auto auto auto;

      .attachments {
        //flex: 33%;
        margin-top: 20px;
        .file_details {
          p {
            color: #41d5c2 !important;
          }
        }
      }
    }
    .hcp-actions {
      margin: auto;
    }

    .input-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 3rem;
      margin-bottom: 1rem;
    }

    // .input-container-minor {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: baseline;
    //   gap: 3rem;
    //   margin-bottom: 1rem;
    //   width: 49%;
    //   // max-width: 36rem;
    // }

    .hcp-common-btn-grp {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    label[data-shrink="false"] {
      color: gray !important;
    }
    .file_attachment_title {
      font-weight: 500 !important;
    }

    .MuiInputBase-inputMultiline {
      border: none !important;
    }
    .file-icon {
      font-size: 6rem;
      color: #41d5c2;
    }

    .travel-preferences-container {
      display: flex;
      gap: 1rem;
    }

    .notification-preferences-container {
      display: flex;
      gap: 3.5rem;
    }
  }
  .contract-file-preview-image-wrapper{
    width: 80px;
    height: 80px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .file-image{
      width: 70px;
      height: 70px;
    }
  }
}