@import "src/assets/scss/common";

.assign-nc {
	.nc-list-wrapper{
		height: 400px;
		overflow-y: auto;
		margin-top: 20px;
		border: 1px solid #D2D2D2;
		padding-left: 15px;
		border-radius: 12px;
	}
    .MuiFormLabel-root{
        color: $primary-color !important;
    }
	.MuiIconButton-label {
		color: $primary-color !important;
	}
	.MuiTypography-body1 {
		color: $light-black;
	}
	.MuiOutlinedInput-inputMarginDense {
		//width: 230px;
		width: 380px;
	}
}
