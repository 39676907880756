@import "src/assets/scss/variables";
.job-list-table-component{
  height: 68vh !important;

  .job-list-table-wrapper{
    margin-top: 20px;

    .job-search{
      width: 400px !important;
    }
    .ant-table-body{
      min-height: 36vh !important;
      max-height: 36vh !important;
    }
    .job-list-button-wrapper{
      margin-top: 20px;
      margin-bottom: 20px;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      .job-list-button{
        background: $primary-color;
      }
    }
  }
}