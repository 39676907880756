
.pipeline-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.pipeline-step {
  text-align: center;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
.updated-at{
  font-size: 10px;
  font-weight: 500;
  color:gray;
  margin-bottom: 5px;
}

/* Line between dots (always gray) */
.line {
  position: absolute;
  width: 100%;
  height: 4px;
  background: #F0F0F0;
  top: 30px; /* Align with the center of circles */
  left: -50%;
  z-index: 8;
}

/* Circle for each step */
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #BFBFBF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  z-index: 9;
}

/* Green ticked circle when status_applied is true */
.completed {
  background: green;
}

/* Timestamp below the circle */
.timestamp {
  font-size: 12px;
  color: #777;
  margin-top: 5px; /* Space between circle and timestamp */
}
