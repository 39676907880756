@import "src/assets/scss/variables";

.single-email-editor-component{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 50px);
  margin-bottom: 20px;

  .single-email-box {
    .email-editor-wrapper {
      width: 100%;
      flex: 2;
      border: 1px solid var(--gray-300, #D4D4D8);
      border-radius: 12px;
      background-color: #FFFFFF;

      .email-editor-dropdown-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 15px;
        border-bottom: 1px solid var(--gray-300, #D4D4D8);

        .email-editor-field {
          width: 33.3%;
        }

        .ant-form-item {
          margin-bottom: 0px !important;
        }
      }

      .email-editor {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        height: 100%;

        .email-subject-input {
          width: 100%;
          padding: 6px;
          font-family: inherit;
          border-bottom: 1px solid var(--gray-300, #D4D4D8);

          .ant-input {
            border: none;
            outline: none;
            box-shadow: none !important;
          }

          .ant-input:focus {
            outline: none;
            box-shadow: none !important;
          }
        }

        .ant-form-item {
          margin-bottom: 0 !important;
        }

        .email-quill-wrapper {
          flex: 1;
          height: 100% !important;
          width: 100% !important;
          overflow: hidden;

          .jodit-react-container{
            height: 450px !important;
          }
        }

        .email-attachment-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          flex-wrap: wrap;
          padding: 12px;


          .attachment-upload {
            cursor: pointer;
            padding: 10px;
          }

          .file-preview-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            border: 1px solid var(--gray-300, #D4D4D8);
            padding: 8px;
            border-radius: 5px;

            .file-preview-close-icon {
              cursor: pointer;
            }
          }
        }
        .email-btn-wrapper{
          display: flex;
          align-items: center;
          gap: 10px;

          .email-save-button {
            background-color: #3ad4c5;
            border: none;
            color: #ffffff;
            height: 35px;
            font-size: 1rem;

            &:hover {
              background-color: #3ad4c5;
              color: #ffffff;
            }
          }
        .delete-btn {
          &:hover {
            background-color: $background-color;
          }
        }
      }
    }
  }
  }
}