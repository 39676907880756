@import "src/assets/scss/variables";

.job-notes-component{
  background: var(--white, #FFF);
  width: 100%;
  //width: 1200px;
  margin: 0 auto;
  overflow: auto;
  height: 100%;
  //max-height: 80vh;
  .job-notes-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .notes-heading{
      font-size: 18px;
      color: black;
      font-weight: 600;
    }
  }
  .job-notes-content-holder{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .job-button {
      background-color: $backdrop-color;
      border: none;
      border-radius: 6px;
      color: #FFF;
      font-size: 14px;
      font-weight: 400;
      padding: 0 20px 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #32b9ab !important;
        color: #FFFFFF;
      }

      .anticon {
        font-size: 16px;
        margin-right: 2px;
      }
    }

    .notes-text-editor{
      border: 1px solid var(--gray-300, #D4D4D8);
      border-radius: 8px;
      margin-top: 10px;
      margin-bottom: 20px;
      .ql-container{
        height: 250px;
        overflow-y:scroll;
      }
    }

    .job-notes-btn-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }
  .job-notes{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-top: 20px;

    .notes-card {
      border-radius: 0.5rem;
      border: 1px solid var(--gray-300, #D4D4D8);
      //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 15px;

      .notes-card-content{
        width: 100%;
        max-width: 950px;
        text-overflow:ellipsis;
        word-break: break-word;
      }
      .notes-author{
        margin-top: 10px;
        font-size: 10px;
        color: #908f8f;
        font-weight: 400;
      }
    }
  }

  .notes-loading-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .loader-notes{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.add-job-button {
  background-color: $backdrop-color;
  border: none;
  border-radius: 6px;
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  padding: 0 20px 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #32b9ab !important;
  }

  .anticon {
    font-size: 16px;
    margin-right: 2px;
  }
}

.menu-note-wrapper{
  .ant-menu-item:hover{
    background: #E4FEFE !important;
  }
  .ant-menu-light{
    border-right: none !important;
  }
}