.add-staff-to-job-component{
  height: 100%;

  .add-staff-to-job-search-wrapper{
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    .staff-search-field{
      width: 250px !important;
    }
    .add-staff-filter{
      width: 250px !important;
      .ant-form-item{
        margin-bottom: 0 !important;
      }
    }
  }
  .add-staff-to-job-list{
    width: 100%;
    margin-top: 20px;
    height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x:hidden;
    border: 1px solid lightgray;
    border-radius: 8px;
    .add-staff-list-item{
      padding-left: 10px;
      .MuiTypography-body1{
        color: #0c0b0b !important;
      }
    }
  }
  .add-staff-btn-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    .add-staff-btn{
      flex: 1;
    }
  }

}