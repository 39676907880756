@import "src/assets/scss/variables";

.applicant-component{

  .applicant-btn-wrapper{
    display: flex;
    align-items: center;
    gap: 25px;
    .applicant-box{
      border: 1px solid lightgray;
      display: flex;
      align-content: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      cursor: pointer;

      .applicant-edit-icon{
        height: 28px;
        width: 22px;
      }
    }
  }

}

.applicant-list-btn-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .job-list-count{
    background: #41D6C3;
    width: 16px;
    height: 16px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
  }
}