@import "../../../assets/scss/common";
@import "../../../assets/scss/variables";

.notification-component {
  display: flex;
  flex-direction: column;
  height:calc(100vh - 150px);
  width: 100%;
  .notification-header {
    color: $primary-color;
    font-size: 28px;
    margin: 1rem 0;
  }

  .notification-preferences {
    //border: 1px solid $light-gray;
    padding: 1rem;
    //border-radius: 8px;
    .notification-input-wrapper{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .notification-group {
      color: $light-black;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .notification-preferences-container {
      display: flex;
      gap: 3.5rem;
      align-items: center;

      .form-control {
        .form-label {
          color: $text-light;
          font-size: medium;
        }
      }
      .ant-form-item{
        margin-bottom: 0 !important;
        width: 250px;
      }
    }
  }

  .custom-border {
    padding: 2rem 3rem 2rem 2rem;
  }
  .ant-divider-horizontal{
    margin: 0 !important;
  }

  .notification-ant-input{
    width: 140px !important;
  }
}
